@tailwind base;
@tailwind components;
@tailwind utilities;

.invisible-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Edge */
}

.invisible-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
  scrollbar-width: none; /* for Firefox */
}

/* .App {
  font-family: sans-serif;
  text-align: center;
} */

.static {
  background: blue;
}

#imported-iframe {
  pointer-events: none;
}

.custom-editor .ql-container.ql-snow {
  border: none;
}

.quill.custom-editor{width: 100%;display: flex;flex-direction: column;}
.quill.custom-editor .ql-toolbar.ql-snow{flex-shrink: 0;flex-grow: 0;max-height: 70px;overflow: auto;}
.quill.custom-editor .ql-container.ql-snow{flex-shrink: 1;flex-grow: 1;height: calc(100% - 70px);}
